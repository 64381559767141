<template>
  <base-material-card
    v-if="campos.length || baseDados === ''"
    color="primary"
    icon="mdi-table-edit"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Campos para atualização</div>
    </template>
    <v-container
      fluid
      class="py-0 mt-7"
    >
      <v-row
        :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'mt-6' : ''"
        v-for="(campoAtualizacao, index) in camposAtualizacao"
        :key="index"
      >
        <v-col
          md="3"
          cols="12"
          class="py-0"
        >
          <v-autocomplete
            class="mt-5"
            :items="campos"
            item-text="column"
            v-model="campoAtualizacao.campo"
            @input="(campo) => updateCampoType(campoAtualizacao, campo)"
            dense
            label="Campo"
            no-data-text="Nenhum campo disponível"
          />
        </v-col>
        <v-col
          md="3"
          cols="12"
          class="py-6"
        >
          <v-icon small> mdi-equal </v-icon>
        </v-col>
        <v-col
          md="5"
          sm="11"
          cols="10"
          class="py-0"
        >
          <input-group-valor
            :ref="`refInputGroupValor${index}`"
            :index="index"
            :filtro.sync="camposAtualizacao[index]"
          />
        </v-col>
        <v-col
          sm="1"
          cols="2"
          class="mt-2 py-0"
        >
          <v-row>
            <v-col cols="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#2B60D6"
                    min-width="0"
                    icon
                    fab
                    x-small
                    @click="addCampo(index)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Campo</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="index > 0"
                    color="error"
                    min-width="0"
                    icon
                    fab
                    x-small
                    @click="removeCampo(index)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Remover Campo</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-material-card>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  mixins: [dateFormatterMixins],
  components: {
    InputGroupValor: () => import('@/components/relatorios/InputGroupValor')
  },
  props: {
    campos: {
      type: Array,
      default: () => []
    },
    baseDados: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    camposAtualizacao: [
      {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      }
    ]
  }),
  methods: {
    updateCampoType(campoAtualizacao, campo) {
      this.campos.forEach((item) => {
        if (item.column == campo) {
          campoAtualizacao.type = item.type;
          campoAtualizacao.funcao = '=';
        }
      });
      this.$forceUpdate();
    },
    addCampo(index) {
      this.camposAtualizacao.push(this.getEmptyFilter());
      this.$refs['refInputGroupValor' + index][0].addMenus();
    },
    removeCampo(target) {
      if (this.camposAtualizacao.length <= 1) {
        this.camposAtualizacao = [this.getEmptyFilter()];
      } else {
        this.camposAtualizacao.splice(target, 1);
        this.$refs['refInputGroupValor' + target][0].removeMenus(target);
      }
    },
    getEmptyFilter() {
      return {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      };
    },
    validarCamposAtualizacao(camposAtualizacao) {
      let retorno = true;
      camposAtualizacao.forEach((campo) => {
        /* Caso o campo de data for do tipo month, ira validar se o valor corresponde a ##/#### e se é valido */
        if (
          campo.monthFormatted &&
          (campo.monthFormatted.indexOf('undefined') != -1 ||
            campo.monthFormatted.length != 7)
        ) {
          this.$toast.warning(
            `O campo [${campo.campo}] deve estar no formato: ##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        } else if (
          campo.monthFormatted2 &&
          (campo.monthFormatted2.indexOf('undefined') != -1 ||
            campo.monthFormatted2.length != 7)
        ) {
          this.$toast.warning(
            `O campo [${campo.campo}] deve estar no formato: ##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        }
        /* Caso o campo de data for do tipo date, ira validar se o valor corresponde a ##/##/#### e se é valido */
        if (
          campo.dateFormatted &&
          (campo.dateFormatted.indexOf('undefined') != -1 ||
            campo.dateFormatted.length != 10)
        ) {
          this.$toast.warning(
            `O campo [${campo.campo}] deve estar no formato: ##/##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        } else if (
          campo.dateFormatted2 &&
          (campo.dateFormatted2.indexOf('undefined') != -1 ||
            campo.dateFormatted2.length != 10)
        ) {
          this.$toast.warning(
            `O campo [${campo.campo}] deve estar no formato: ##/##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        }
      });
      return retorno;
    },
    exportCampos() {
      let isValid = this.validarCamposAtualizacao(this.camposAtualizacao);
      return isValid ? this.camposAtualizacao : 'invalido';
    }
  }
};
</script>
